import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const PopupFlyer = () => {
  const [showModal, setShowModal] = useState(true);

  // Check if the modal has been shown before (using local storage)
  useEffect(() => {
    const hasShownModal = localStorage.getItem("hasShownModal");
    if (!hasShownModal) {
      setShowModal(true);
      localStorage.setItem("hasShownModal", "true");
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Go to events page to register!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src="img/events/modal.jpg" width="100%" />
      </Modal.Body>
      <Modal.Footer>
        <NavLink to="/events">
          <Button variant="secondary" onClick={handleClose}>
            Events Page
          </Button>
        </NavLink>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupFlyer;
