import React from "react";
import { useState } from "react";
import { Container, Button, Carousel, Row, Col } from "react-bootstrap";
import UpcomingEvents from "../../components/page-components/UpcomingEvents";

import styles from "./EventScreen.module.css";
import FadeInAnimation from "../../components/animations/FadeInAnimation";

const EventScreen = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [viewMode, setViewMode] = useState("mainMenu");
  const events = [
    {
      id: 1,
      name: "Annual LOYM Spring 2024 Event",
      images: [
        "/img/Spring-2024/index.jpg",
        "/img/Spring-2024/1.jpeg",
        "/img/Spring-2024/2.jpeg",
        "/img/Spring-2024/3.jpeg",
        "/img/Spring-2024/4.jpeg",
        "/img/Spring-2024/5.jpeg",
        "/img/Spring-2024/6.jpeg",
        "/img/Spring-2024/7.jpeg",
        "/img/Spring-2024/8.jpeg",
        "/img/Spring-2024/9.jpeg",
        "/img/Spring-2024/10.jpeg",
        "/img/Spring-2024/11.jpeg",
        "/img/Spring-2024/12.jpeg",
        "/img/Spring-2024/13.jpeg",
      ],
    },

    {
      id: 2,
      name: "Annual LOYM Fall 2023 Event",
      images: [
        "/img/Fall-2023/1.jpg",
        "/img/Fall-2023/2.jpeg",
        "/img/Fall-2023/3.jpeg",
        "/img/Fall-2023/4.jpeg",
        "/img/Fall-2023/5.jpeg",
        "/img/Fall-2023/6.jpeg",
        "/img/Fall-2023/7.jpeg",
        "/img/Fall-2023/8.jpeg",
        "/img/Fall-2023/9.jpeg",
        "/img/Fall-2023/10.jpeg",
        "/img/Fall-2023/11.jpeg",
        "/img/Fall-2023/12.jpeg",
        "/img/Fall-2023/13.jpeg",
        "/img/Fall-2023/14.jpeg",
        "/img/Fall-2023/15.jpeg",
        "/img/Fall-2023/16.jpeg",
        "/img/Fall-2023/17.jpeg",
        "/img/Fall-2023/18.jpeg",
        "/img/Fall-2023/19.jpeg",
        "/img/Fall-2023/20.jpeg",
      ],
    },
    {
      id: 3,
      name: "Annual LOYM Fall 2022 Event",
      images: [
        "/img/Fall-2022/index.png",
        "/img/Fall-2022/1.jpeg",
        "/img/Fall-2022/2.jpeg",
        "/img/Fall-2022/4.jpeg",
        "/img/Fall-2022/8.jpeg",
        "/img/Fall-2022/9.jpeg",
        "/img/Fall-2022/10.jpeg",
        "/img/Fall-2022/11.jpeg",
        "/img/Fall-2022/6.jpeg",
        "/img/Fall-2022/7.jpeg",
        "/img/Fall-2022/5.jpeg",
        "/img/Fall-2022/3.jpeg",
        "/img/Fall-2022/12.jpeg",
      ],
    },
    {
      id: 4,
      name: "Annual LOYM Spring 2022 Event",
      images: [
        "/img/Spring-2022/index.jpg",
        "/img/Spring-2022/1.jpg",
        "/img/Spring-2022/2.jpg",
        "/img/Spring-2022/3.jpg",
        "/img/Spring-2022/4.jpg",
        "/img/Spring-2022/5.jpg",
        "/img/Spring-2022/6.jpg",
        "/img/Spring-2022/7.jpg",
        "/img/Spring-2022/8.jpg",
        "/img/Spring-2022/9.jpg",
        "/img/Spring-2022/10.jpg",
        "/img/Spring-2022/11.jpg",
        "/img/Spring-2022/12.jpg",
        "/img/Spring-2022/13.jpg",
        "/img/Spring-2022/14.jpg",
      ],
    },
    {
      id: 5,
      name: "Annual LOYM Fall 2021 Event",
      images: [
        "/img/Fall-2021/1.jpg",
        "/img/Fall-2021/2.jpg",
        "/img/Fall-2021/3.jpg",
        "/img/Fall-2021/4.jpg",
        "/img/Fall-2021/8.jpg",
        "/img/Fall-2021/9.jpg",
      ],
    },
    // Add more events as needed
  ];

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setViewMode("eventDetails");
  };

  const handleReturnToMainMenu = () => {
    setSelectedEvent(null);
    setViewMode("mainMenu");
  };

  const buttonStyle = {
    backgroundColor: "#7e7477ff",
    color: "#ffffff",
    fontFamily: "Roboto Condensed, sans-serif",
    textAlign: "center",
    padding: "10px",
    margin: "auto",
    display: "block",
  };

  return (
    <Container>
      <h1 className="title">Upcoming Events</h1>
      <FadeInAnimation>
        <UpcomingEvents />
      </FadeInAnimation>
      <hr />

      {viewMode === "mainMenu" && (
        <div className="event-images">
          <h1 className="title">Past Events</h1>
          <FadeInAnimation>
            <Row className="justify-content-md-center">
              {events.map((event) => (
                <Col sm={12} lg={4}>
                  <div className={styles.eventContainer}>
                    <img
                      key={event.id}
                      src={event.images[0]}
                      alt={event.name}
                      onClick={() => handleEventClick(event)}
                      className={styles.eventImg}
                    />
                    <p className={styles.caption}>
                      Click image to see pictures from
                      <hr />
                      {event.name}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </FadeInAnimation>
        </div>
      )}
      {viewMode === "eventDetails" && selectedEvent && (
        <div className="event-details">
          <br />
          <h1 className="header">{selectedEvent.name}</h1>
          <Carousel className={styles.carousel}>
            {selectedEvent.images.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  src={image}
                  alt={`Event ${selectedEvent.id}`}
                  height="auto"
                  className={styles.carouselImg}
                />
              </Carousel.Item>
            ))}
          </Carousel>
          <br />
          <Button style={buttonStyle} onClick={handleReturnToMainMenu}>
            Return to Past Events
          </Button>
          <br />
        </div>
      )}
    </Container>
  );
};

export default EventScreen;
