import React from "react";
import { Row, Col } from "react-bootstrap";

const ScholarshipScreen = () => {
  return (
    <>
      <h1 className="title">The LOYM Caregiver Scholarship</h1>;
      <Row className="justify-content-md-center">
        <Col sm={6}>
          <div className="text-box">
            <p>
              LOYM'S Caregiver Scholarship is awarded to a deserving Mom that
              provides care to a family remember due to illness or to a child
              with special needs. Our Founder. Jackie Hardy started the
              scholarship to honor her Mother & Father. Her Mother was diagnosed
              with breast cancer in 2023 and she saw firsthand the sacrifice
              caregivers provide through her Father who has be faithful care
              giver to her Mom.
            </p>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <h2 className="text-center">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSctUYCPZcgVbMtwa7sU2zIyZJupWIcQgys317K_Oqg44VhIEA/viewform"
          target="_blank"
          className="button lg"
        >
          Click here to apply
        </a>
      </h2>
      <br />
      <br />
      <hr />
      <br />
      <h1 className="title">Scholarship Recipients:</h1>
      <Row className="justify-content-md-center">
        <Col sm={12} lg={6}>
          <img src="/img/scholarship/2023-1.jpg" height="100%" width="100%" />
        </Col>
        <Col sm={12} lg={6}>
          <img src="/img/scholarship/2023-2.jpeg" height="100%" width="100%" />
        </Col>
      </Row>
    </>
  );
};

export default ScholarshipScreen;
