import { configureStore } from "@reduxjs/toolkit";
import upcomingEventsReducer from "./slices/upcomingEventsSlice";

const store = configureStore({
  reducer: {
    upcomingEvents: upcomingEventsReducer, // Add your slice reducer to the store
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true,
});

export default store;
