import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Container.module.css";

const TextContainer = ({ children }) => {
  return (
    <Container className={`${styles.TextContainer}`}>{children}</Container>
  );
};

export default TextContainer;
