import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import FadeInAnimation from "../../components/animations/FadeInAnimation";

import styles from "./StatementScreen.module.css";

const StatementScreen = () => {
  return (
    <Container>
      <h1 className="title">Statement of Faith </h1>

      <Row className="justify-content-md-center">
        <Col xs={12}>
          <FadeInAnimation>
            <img
              src="/img/page-images/LOYM_statement/LOYM_statement_of_faith_1.svg"
              alt="Statement of Faith and Core Values"
              className={`${styles.coreImg} img-center`}
            />
          </FadeInAnimation>
        </Col>
        <Col xs={12}>
          <FadeInAnimation>
            <img
              src="/img/page-images/LOYM_statement/LOYM_statement_of_faith_2.svg"
              alt="Statement of Faith and Core Values"
              className={`${styles.coreImg} img-center`}
            />
          </FadeInAnimation>
        </Col>
      </Row>
      <br />
    </Container>
  );
};

export default StatementScreen;
