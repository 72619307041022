// RisingInAnimation.js
import React from "react";
import styles from "./Animations.module.css";
import withVisibilityAnimation from "./withVisibilityAnimation";

const RisingInAnimation = ({ children }) => {
  return <div className={styles.RisingInAnimation}>{children}</div>;
};

export default withVisibilityAnimation(
  RisingInAnimation,
  styles.RisingInAnimation
);
