import React, { useEffect, useRef, useState } from "react";

const withVisibilityAnimation = (WrappedComponent, animationClass) => {
  return (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    };

    useEffect(() => {
      const observer = new IntersectionObserver(handleIntersection, {
        threshold: calculateThreshold(), // Initial threshold based on screen width
      });

      if (elementRef.current) {
        observer.observe(elementRef.current);
      }

      const handleResize = () => {
        observer.disconnect();
        observer.observe(elementRef.current);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        if (elementRef.current) {
          observer.unobserve(elementRef.current);
        }
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const calculateThreshold = () => {
      const screenWidth = window.innerWidth;

      // Adjust the threshold based on screen width
      if (screenWidth < 768) {
        return 0.3; // For small screens
      } else {
        return 0.5; // For larger screens
      }
    };

    return (
      <div
        ref={elementRef}
        className={isVisible ? animationClass : ""}
        style={{ opacity: isVisible ? 1 : 0 }}
      >
        <WrappedComponent {...props} />
      </div>
    );
  };
};

export default withVisibilityAnimation;
