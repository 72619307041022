import React from "react";
import { Row, Col } from "react-bootstrap/esm/";
import Container from "react-bootstrap/esm/Container";

import styles from "./WhoScreen.module.css";
import RisingInAnimation from "../../components/animations/RisingInAnimation";
import FadeInAnimation from "../../components/animations/FadeInAnimation";
const WhoScreen = () => {
  return (
    <>
      <Container>
        <h1 className="title">Who we are</h1>
        <Row>
          <Col lg={6} md={12}>
            <FadeInAnimation>
              <Container>
                <img
                  src="/img/page-images/mission-img.png"
                  className={`img-center ${styles.whoImg}`}
                />
              </Container>
            </FadeInAnimation>
          </Col>
          <Col lg={6} md={12}>
            <RisingInAnimation>
              <p className={styles.whoText}>
                The Mission of Love on You Mommy Ministry is based on Isaiah
                62:3. It's our mission to affirm and assert Moms of all various
                ages, backgrounds, and stages in life of this unconditional
                truth through the effective practice of Engaging, Encouraging,
                and Empowering Moms to confidently embrace who they are both
                maternally and individually.
              </p>
            </RisingInAnimation>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WhoScreen;
