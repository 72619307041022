import { createSlice } from "@reduxjs/toolkit";

const upcomingEventsSlice = createSlice({
  name: "upcomingEvents",
  initialState: {
    events: [], //
  },
  reducers: {
    setEvents: (state, action) => {
      state.events = action.payload;
    },
  },
});
export const { setEvents } = upcomingEventsSlice.actions;
export default upcomingEventsSlice.reducer;
