import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./page-components.module.css";

//Upcoming Events Grid

export const UpcomingEvents = () => {
  const events = [
    // {
    //   id: 1,
    //   imageUrl: "/img/events/Upcomingevents2024.jpeg",
    //   // title: "LOYM Spring Event",
    //   // description:
    //   //   "Registration to open soon. Attendees will be responsible for meals.",
    //   // registerUrl:
    //   //   "https://docs.google.com/forms/u/1/d/e/1FAIpQLSdPrC54tQsrjHG3CB56eSokz3H5rJJT49xSke5uyzSP766znQ/viewform",
    // },
    {
      id: 2,
      imageUrl: "/img/events/Upcomingevents2024(1).jpeg",
      // title: "LOYM Spring VBS",
    },
    {
      id: 3,
      imageUrl: "/img/events/modal.jpg",
      title: "LOYM Fall Event",
      description: `LOYM Fall Event: Register TODAY and reserve your spot!!
      \nPlease click on the button to access the online registration form.  `,
      registerUrl:
        "https://docs.google.com/forms/d/e/1FAIpQLScSm6ZbNfddj8PIhUKYS3j3JETVQMrf_1THruQcMeQ8DmF1HQ/viewform",
    },
    // Add more events as needed
  ];

  // Mom wanted no overlay for this most recent build which is why so much is commented out
  return (
    <Row className="justify-content-md-center">
      {events.map((event) => (
        <Col key={event.id} md={6} sm={12}>
          <div className={styles.eventCard}>
            <div className={styles.imageContainer}>
              <img
                src={event.imageUrl}
                alt={event.title}
                className={styles.eventImage}
                fluid
              />
              <div className={styles.overlay}>
                <h3 className={styles.title}>{event.title}</h3>
                <p className={styles.description}>{event.description}</p>
                {event.registerUrl && (
                  <a
                    href={event.registerUrl}
                    target="_blank"
                    className="button"
                  >
                    Click Here
                  </a>
                )}
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
    // <Row className="justify-content-md-center">
    //   {events.map((event) => (
    //     <Col key={event.id} md={6} sm={12}>
    //       <div className={styles.eventCard}>
    //         <h3 className={styles.title}>{event.title}</h3>
    //         <div className={styles.imageContainer}>
    //           <a href={event.registerUrl} target="_blank">
    //             <img
    //               src={event.imageUrl}
    //               alt={event.title}
    //               className={styles.eventImage}
    //               fluid
    //             />
    //           </a>
    //         </div>
    //         <p className={styles.description}>{event.description}</p>
    //       </div>
    //     </Col>
    //   ))}
    // </Row>
  );
};

export default UpcomingEvents;
