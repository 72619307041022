import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import FadeInAnimation from "../../components/animations/FadeInAnimation";
import RisingInAnimation from "../../components/animations/RisingInAnimation";

const ResourceScreen = () => {
  const handleDownload = () => {
    // Replace 'your_document.pdf' with the actual filename
    const filename = "LOYM_tax_exempt.tif";
    const url = `/`;

    // Create a virtual anchor element
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    // Trigger a click on the anchor element
    document.body.appendChild(link);
    link.click();

    // Remove the anchor element
    document.body.removeChild(link);
  };
  return (
    <Container>
      <br />
      <br />
      <Row className="justify-content-md-center">
        <Col sm={12} lg={6}>
          <h2 className="text-center">Get The Resources</h2>
          <br />
          <br />
          <FadeInAnimation>
            <h2 className="text-center">
              <a
                href="https://www.blkpawnpublishing.com/pages/the-un-christmas-downloadable-content"
                target="_blank"
                className="button lg"
              >
                Click Here
              </a>
            </h2>
          </FadeInAnimation>
        </Col>
      </Row>
      <br />
      <h1 className="header">OR</h1>
      <br />
      <Row className="justify-content-md-center">
        <Col sm={12} lg={6}>
          <h2 className="text-center">Click On Cover to be Get the Book</h2>
          <br />
          <br />
          <RisingInAnimation>
            <a href="https://www.blkpawnpublishing.com/" target="_blank">
              <div className="img-center">
                <img
                  src="/img/un_christmas_book.jpeg"
                  height="70%"
                  width="100%"
                />
              </div>
            </a>
          </RisingInAnimation>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <br />

      {/* <RisingInAnimation>
        <Row className="justify-content-md-center">
          <Col xs={12} md={5}>
            <br />
            <p className="text-box">
              Please click{" "}
              <a onClick={handleDownload} className="link">
                here
              </a>{" "}
              to download our 501 c 3 tax e-exempt determination letter
            </p>
          </Col>
          <Col xs={12} md={{ span: 5, offset: 2 }}>
            <br />
            <p className="text-box">
              Our annual information return (IRS Form 990) is available upon
              request via email to loveonyoumommy@gmail.com. Connect to us
            </p>
          </Col>
        </Row>
      </RisingInAnimation> */}
    </Container>
  );
};

export default ResourceScreen;
