import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import FadeInAnimation from "../../components/animations/FadeInAnimation";
import RisingInAnimation from "../../components/animations/RisingInAnimation";

const DonateScreen = () => {
  const handleDownload = () => {
    // Replace 'your_document.pdf' with the actual filename
    const filename = "LOYM_tax_exempt.tif";
    const url = `/`;

    // Create a virtual anchor element
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    // Trigger a click on the anchor element
    document.body.appendChild(link);
    link.click();

    // Remove the anchor element
    document.body.removeChild(link);
  };
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col sm={12} lg={6}>
          <h1 className="header">Why donate?</h1>
          <br />
          <FadeInAnimation>
            <p className="text-box">
              We are a tax-deductible nonprofit that serves Moms locally and
              internationally. Donating to LOYM will allow us to continue to
              help Moms be engaged, encouraged and empowered and by helping
              Moms, we are ultimately helping to make our world a better place
              as Moms are and will always be the bedrock to strong and healthy
              society.
            </p>
          </FadeInAnimation>
        </Col>
      </Row>
      <br />
      <br />
      <hr />
      <Row className="justify-content-md-center">
        <Col sm={12} lg={6}>
          <br />
          <h1 className="header">Click on image to donate</h1>
          <FadeInAnimation>
            <a
              href="https://loym.givingfuel.com/love-on-you-mommy-ministries"
              target="blank"
            >
              <img src="/img/loym_donate.jpg" width="100%" />
            </a>
          </FadeInAnimation>
        </Col>
      </Row>
      <br />
      <br />
      <hr />
      <br />
      <br />
      <RisingInAnimation>
        <Row className="justify-content-md-center">
          <Col xs={12} md={5}>
            <br />
            <p className="text-box">
              Please click{" "}
              <a onClick={handleDownload} className="link">
                here
              </a>{" "}
              to download our 501 c 3 tax e-exempt determination letter
            </p>
          </Col>
          <Col xs={12} md={{ span: 5, offset: 2 }}>
            <br />
            <p className="text-box">
              Our annual information return (IRS Form 990) is available upon
              request via email to loveonyoumommy@gmail.com. Connect to us
            </p>
          </Col>
        </Row>
      </RisingInAnimation>
    </Container>
  );
};

export default DonateScreen;
