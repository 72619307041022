import React from "react";

import styles from "./Animations.module.css";
import withVisibilityAnimation from "./withVisibilityAnimation";

// FadeInAnimation.js
const FadeInAnimation = ({ children }) => {
  return <div className={styles.FadeInAnimation}>{children}</div>;
};

export default withVisibilityAnimation(FadeInAnimation, styles.FadeInAnimation);
