import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";

import { LinkContainer } from "react-router-bootstrap";

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  /* INLINE CSS */
  const brandStyles = {
    fontFamily: "Satisfy, cursive",
    margin: "1px",
  };

  const headerStyle = {
    backgroundColor: "#7e7477ff",
    color: "white !important",
    fontFamily: "PT Sans Narrow, sans-serif",
  };

  const dropdownStyles = {
    backgroundColor: "inherit",
    maxHeight: showDropdown ? "700px" : "0px",
    opacity: showDropdown ? 1 : 0,
    transition: "max-height 1s ease, opacity 1s ease",
    overflow: "hidden",
    fontFamily: "PT Sans Narrow, sans-serif",
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" style={headerStyle} variant="dark">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <a style={brandStyles}>Love on You Mommy</a>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <NavDropdown
                title="About"
                id="collasible-nav-dropdown"
                onMouseEnter={() => {
                  setShowDropdown(true);
                  console.log("Click", setShowDropdown);
                }}
                onMouseLeave={() => {
                  setShowDropdown(false);
                  console.log("UnClick", setShowDropdown);
                }}
              >
                <div style={dropdownStyles}>
                  <LinkContainer to="/whoweare">
                    <NavDropdown.Item>Who We Are</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/values">
                    <NavDropdown.Item>What We Value</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/statement">
                    <NavDropdown.Item>
                      Statement of Faith & Core Values
                    </NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to="/history">
                    <NavDropdown.Item>Our History</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/founder">
                    <NavDropdown.Item>Our Founder</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/board">
                    <NavDropdown.Item>
                      Our Board and Committies
                    </NavDropdown.Item>
                  </LinkContainer>
                </div>
              </NavDropdown>
              <LinkContainer to="/events">
                <Nav.Link>Events</Nav.Link>
              </LinkContainer>
              <NavDropdown
                title="Community Outreach"
                id="collasible-nav-dropdown"
                onMouseEnter={() => {
                  setShowDropdown(true);
                  console.log("Click", setShowDropdown);
                }}
                onMouseLeave={() => {
                  setShowDropdown(false);
                  console.log("UnClick", setShowDropdown);
                }}
              >
                <div style={dropdownStyles}>
                  <LinkContainer to="/outreach">
                    <NavDropdown.Item>Service</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/scholarship">
                    <NavDropdown.Item>Caregiver Scholarship</NavDropdown.Item>
                  </LinkContainer>
                </div>
              </NavDropdown>

              <LinkContainer to="/resources">
                <Nav.Link>Resources</Nav.Link>
              </LinkContainer>

              <LinkContainer to="/donate">
                <Nav.Link>Donate</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
