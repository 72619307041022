import React from "react";
import { Carousel, CarouselItem, Container } from "react-bootstrap";
import styles from "./page-components.module.css";
import { LinkContainer } from "react-router-bootstrap";

// Used for cycling through testimonals about LOYM

const TestimonialCarousel = () => {
  const testimonials = [
    {
      id: 1,
      title: "Monti D.",
      content: `"I am so thankful and grateful for LOYM Ministry. The love that is poured out is exactly what this Mommy needs when I’m 
        feeling lost, alone or tired! Jackie Hardy was given the gift of..."`,
    },
    {
      id: 2,
      title: `Janet C.`,
      content: `"I feel blessed BEYOND MEASURE to have been invited to the 1st Annual Love on You Mommy Christmas brunch OVER A DECADE ago! 
      Those brunches quickly became one of the highlights of my year..."`,
    },
    {
      id: 3,
      title: `Vanetra G.`,
      content: `"LOYM Ministry is an outlet for Moms to let go and be free, to be pampered, and loved. A ministry just for us - 
      to refresh our souls, connect, kick our feet up, let our hair down..."`,
    },
    {
      id: 4,
      title: `Susan J.`,
      content: `"I met Jackie back in 2013 and I have always felt and appreciated her acceptance of me with open arms. Her natural aura of 
      kindness and love is admirable! Because her amazing..."`,
    },
    {
      id: 5,
      title: `Trenise S., Carrollton/Farmers Branch ISD`,
      content: `"Thank you for the Amazon.com gift card.  It is greatly appreciated and will be put to good use."`,
    },
    {
      id: 6,
      title: `Judith K., Carrollton/Farmers Branch ISD`,
      content: `"Thank you for thinking about teachers...I know teachers really appreciate your thoughtfulness...God bless you!"`,
    },
    {
      id: 7,
      title: `Rhonda H., Private School Teacher`,
      content: `"I am honored to receive a gift card. I will put it to good use for my kindergarten classroom."`,
    },
    // Add more testimonials as needed
  ];

  return (
    <Container>
      <Carousel fade>
        {testimonials.map((testimonial) => (
          <Carousel.Item
            key={testimonial.id}
            className={styles.carouselItem}
            interval={10000}
          >
            <Container>
              <h3 className={styles.carouselHeader}>{testimonial.title}</h3>
              <p className={styles.carouselText}>{testimonial.content}</p>
            </Container>

            <LinkContainer to="/testimonial">
              <p className={`${styles.carouselText} ${styles.link}`}>
                Click here for more
              </p>
            </LinkContainer>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default TestimonialCarousel;
