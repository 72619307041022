import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import styles from "./Container.module.css";

const VideoContainer = ({ children }) => {
  return (
    <Container className={`${styles.VideoContainer}`}>
      <Row className={` justify-content-center mt-5`}>
        <Col xs={12} md={8}>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default VideoContainer;
