import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import FadeInAnimation from "../../components/animations/FadeInAnimation";

import styles from "./FounderScreen.module.css";
import RisingInAnimation from "../../components/animations/RisingInAnimation";

const HistoryScreen = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col sm={12} lg={6} className="d-flex align-items-center">
          <img
            src="/img/committees/founderpic.jpg"
            height="100%"
            width="100%"
            className={styles.round}
          />
        </Col>
        <Col sm={12} lg={6}>
          <h1 className="header">Our Founder</h1>
          <div className="text-box">
            <p>
              Jackie Hardy has such a heart for Moms because she understands
              firsthand how a Sovereign God can beautifully weave the peaks and
              the valleys of Motherhood to create your own unique story. And
              that's exactly what God did for Jackie as He took her shortcomings
              and strengths to write this fascinating story that entails
              brokenness, failures, unconditional love, and adventure all
              centered around one of the greatest calls of her life--Motherhood!
              <br />
              <br />
              When Jackie became a Mom, she felt many times inept and
              unqualified to be the "perfect" Mother to her first born. It was
              during those moments of doubt and insecurities that her loving and
              compassionate Father came alongside her to patiently and gently
              guide her to HIS unwavering truth that HIS power was being made
              perfect in her weakness (imperfections) [II Cor 12:9]. Those
              imperfections of anger, impatience, and pride not for once changed
              how her Heavenly Father felt about her and it was in those lonely
              seasons of sleep deprivation, endless diaper changing and feedings
              she began to fully comprehend and receive God's abiding love for
              her.
              <br />
              <br />
              After her second child, Jackie's belief in this truth that as Paul
              so profoundly wrote at the end of the verse written in II
              Corinthians 12:9, "So now I am glad to boast about my weaknesses,
              so that the power of Christ can work through me," was cemented and
              it was the genesis to the burden God began to stir in her heart
              for other Moms who also struggled with this truth as she like Paul
              knew it was those very imperfections that God would use to work
              through her to encourage others.
              <br />
              <br />
              Jackie knew her struggles were other Moms' struggles and it was
              then that God began to give her a vision for what is now, Love on
              You Mommy Ministry and her desire for Moms to be Engaged,
              Encouraged, and Empowered.
              <br />
              <br />
              But Jackie understands, her first and foremost ministry is to her
              perfectly designed and sculpted for her husband, Ed and their two
              smart, funny, and Christ-centered children, their son, Darien, who
              is affectionately referred to as DJ and their daughter, Jaella or
              as she is mostly referred to as Jae-Jae. They live in the North
              Fort Worth area and her family loves spending time together as
              well as seeing who can be the corniest, which without question
              that title is usually secured by her husband, Ed.
              <br />
              <br />
              Jackie also serves as a freelance writer for a reputable news
              publication within the Dallas/Fort Worth area as well as she loves
              serving at her local church. Her ultimate hope and desire is that
              Moms will come to know as she now understands that God always sees
              them as written in Isaiah 62:3 "You'll be a stunning crown in the
              palm of God's hand, a jeweled gold cup held high in the hand of
              your God."
            </p>
          </div>
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  );
};

export default HistoryScreen;
