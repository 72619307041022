import React from "react";
import { Container, Carousel, Card } from "react-bootstrap";

import styles from "./TestimonialScreen.module.css";

const TestimonalScreen = () => {
  const testimonials = [
    {
      id: 1,
      imageUrl: "/img/testimonial/MD.jpg",
      name: "Monti D.",
      description: `"I am so thankful and grateful for LOYM Ministry. The love that is poured out is exactly what this Mommy needs when I’m 
        feeling lost, alone or tired! Jackie Hardy was given the gift of making one feel loved and wanted, and the Lord truly speaks 
        through her and her works, all while keeping it real and FUN. Being a part of this Ministry is an honor and I’m so grateful for it."`,
    },
    {
      id: 2,
      imageUrl: "/img/testimonial/JC.jpg",
      name: "Janet C.",
      description: `"I feel blessed BEYOND MEASURE to have been invited to the 1st Annual Love on You Mommy Christmas brunch OVER A DECADE ago! 
      Those brunches quickly became one of the highlights of my year. No matter what interaction it is with LOYM, whether it be attending the 
      Christmas brunch, catching a video update, or attending the Spring event, there has never been a time that I don't have my cup filled. 
      God is so good... there have been so many times that the message that is being delivered is EXACTLY what I needed at that moment or have 
      received practical tips to tackle a challenge I might be facing. LOYM definitely lives up to it's mission... LOYM always engages, 
      encourages, empowers, and even convicts me to embrace who I am as a mother. So grateful for this ministry!"`,
    },
    {
      id: 3,
      imageUrl: "/img/testimonial/VG.jpg",
      name: "Vanetra G.",
      description: `"I met Jackie back in 2013 and I have always felt and appreciated her acceptance of me with open arms. Her natural aura of 
      kindness and love is admirable! Because her amazing qualities motivate the mission of LOYM, her love is contagious. I cannot thank 
      Jackie and LOYM enough for steadfastly engaging, encouraging, and empowering me to be the best mom I can be for my children."`,
    },
    {
      id: 4,
      imageUrl: "/img/testimonial/SJ.jpg",
      name: "Susan J.",
      description: `"LOYM Ministry is an outlet for Moms to let go and be free, to be pampered, and loved. A ministry just for us - 
      to refresh our souls, connect, kick our feet up, let our hair down, and fellowship with other women who care and understand. 
      It’s a space where we can feel comfortable, lay our burdens down, be exposed and transparent without feeling judged. A space where 
      moms can take off the facade and admit fears and weaknesses. A space to share experiences and to encourage. A space to become 
      validated in our mistakes and to be encouraged in our strengths. A space to comfort, nourish, and love each other. A space where there 
      is no failure, no regrets, and no negative self energy - just learning experiences, stepping stones, and forward progress. 
      A space where there is no judgment for not knowing exactly what’s next, just encouragement in God’s Word and faith in His plan."`,
    },
    {
      id: 5,
      name: `Trenise S., Carrollton/Farmers Branch ISD`,
      description: `"Thank you for the Amazon.com gift card.  It is greatly appreciated and will be put to good use."`,
    },
    {
      id: 6,
      name: `Judith K., Carrollton/Farmers Branch ISD`,
      description: `"Thank you for thinking about teachers...I know teachers really appreciate your thoughtfulness...God bless you!"`,
    },
    {
      id: 7,
      name: `Rhonda H., Private School Teacher`,
      description: `"I am honored to receive a gift card. I will put it to good use for my kindergarten classroom."`,
    },
    // Add more events as needed
  ];
  return (
    <Container>
      <h1 className="title">Testimonials</h1>
      <br />
      <Carousel fade>
        {testimonials.map((testimonial) => (
          <Carousel.Item
            key={testimonial.id}
            className={styles.carouselItem}
            interval={3000}
          >
            <Card className={styles.cardStyles}>
              {/* <Card.Img
                variant="top"
                src={testimonial.imageUrl}
                className={styles.cardImg}
              /> */}
              <Card.Body>
                <Card.Title className={styles.cardTitle}>
                  {testimonial.name}
                </Card.Title>
                <Card.Text className={styles.cardText}>
                  {testimonial.description}
                </Card.Text>
              </Card.Body>
            </Card>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default TestimonalScreen;
