import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import styles from "./HistoryScreen.module.css";
import FadeInAnimation from "../../components/animations/FadeInAnimation";

const HistoryScreen = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col sm={12} lg={6}>
          <h1 className="header">Our History</h1>
          <br />
          <FadeInAnimation>
            <div className={`${styles.text} text-box`}>
              <p>
                In 2010, Jackie Hardy started Love on You Mommy (LOYM) Ministry
                out of their home. She held the first Love on You Mommy event in
                December of that year. Hardy felt God tugging on her heart to
                create a ministry that would foster an environment, where Moms
                could come to experience the following:
                <br />
                <br />
                Engage with other Moms through intentional times designated to
                create authentic connections
                <br />
                <br />
                Encourage Moms in providing a safe space, where they can share
                their struggles as well as victories that come with the blessing
                of Motherhood
                <br />
                <br />
                Empower Moms to boldly assert their call and purpose through the
                edification of God’s word
                <br />
                <br />
                For the first eight years, LOYM hosted a Christmas Brunch in the
                home of Jackie Hardy and in 2018, she added a second event
                during the spring for Moms to come and partake in the LOYM Tea,
                Treats, and Teachings. The spring event typically occurs at a
                tea room, where in the past they have held events at the
                now-defunct Cocoa’s Tea Room in Colleyville, TX and the Rose
                Garden Tea Room in Arlington, TX.
                <br />
                <br />
                Over the years, LOYM Ministry has ministered to several Moms and
                has helped to give Moms with children of all ages a hope that
                God has equipped them to endure all seasons that come with
                Motherhood, remind them their value goes beyond being a Mom
                while also giving them permission to take time for themselves.
                <br />
                <br />
                LOYM also gives back to the community as each year during the
                Christmas Holiday they donate gift cards to the non-profit
                organization, Lil4s Kids’ Club, a mentoring and tutoring program
                for children in Sulphur Springs, TX.
              </p>
            </div>
          </FadeInAnimation>
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  );
};

export default HistoryScreen;
