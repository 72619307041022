import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import FadeInAnimation from "../../components/animations/FadeInAnimation";
import RisingInAnimation from "../../components/animations/RisingInAnimation";

import styles from "./ValueScreen.module.css";

const ValueScreen = () => {
  return (
    <>
      <Container>
        <h1 className="title">Why do we exist?</h1>
        <br />
        <br />
        <Row className="justify-content-md-center">
          <Col sm={12} md={6} lg={4}>
            <FadeInAnimation>
              <RisingInAnimation>
                <h2 className={styles.header}>
                  We exist to <a>encourage</a>
                </h2>
              </RisingInAnimation>
            </FadeInAnimation>
            <p>
              Love on You Mommy Ministry will ENCOURAGE Moms by creating a safe
              space for Moms to share freely and be authentic about the
              challenges and struggles of being a Mom in today's culture, where
              they are being constantly bombarded with the message of "I'm not
              enough."
            </p>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <FadeInAnimation>
              <RisingInAnimation>
                <h2 className={styles.header}>
                  We exist to <a>engage</a>
                </h2>
              </RisingInAnimation>
            </FadeInAnimation>
            <p>
              Love on You Mommy Ministry will ENGAGE Moms through LOYM Bi-annual
              Events:
              <br />
              <br />
              Fall Event: Christmas Brunch Spring Event: Tea, Treats, and
              Teachings
              <br />
              <br />
              These events are sacred and intentional times of fellowship with
              the ultimate purpose for Moms to form authentic connections and
              bonds.
            </p>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <FadeInAnimation>
              <RisingInAnimation>
                <h2 className={styles.header}>
                  We exist to <a>empower</a>
                </h2>
              </RisingInAnimation>
            </FadeInAnimation>
            <p>
              Love on You Mommy Ministry will EMPOWER Moms through the
              edification of God's word via written and spoken word by
              formidable women as well as Moms of faith that are highly regarded
              and respected leaders within their area of ministry and/or
              biblical studies.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ValueScreen;
