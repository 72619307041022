// import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FadeInAnimation from "../../components/animations/FadeInAnimation";
import VideoContainer from "../../components/containers/VideoContainer";
import TextContainer from "../../components/containers/TextContainer";
// import { useDispatch, useSelector } from "react-redux";
// import { setEvents } from "../../slices/upcomingEventsSlice";
import { UpcomingEvents } from "../../components/page-components/UpcomingEvents";
import TestimonalCarousel from "../../components/page-components/TestimonialCarousel";

import styles from "./HomeScreen.module.css";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
//{
// const UpcomingEvents = () => {
//   const upcomingEvents = useSelector((state) => state.upcomingEvents.events);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     // Dispatch action to set initial events when component mounts
//     const initialEvents = ["event1", "event2"]; // Replace with your initial events
//     dispatch(setEvents(initialEvents));
//   }, []); // Empty dependency array ensures this effect runs only once on mount

//   const firstEvent = upcomingEvents[0]; // Access the first event

//   return (
//     <div>
//       <h1>Upcoming Event</h1>
//       <p>{firstEvent}</p>
//     </div>
//   );
// };
//}
const HomeScreen = () => {
  // const upcomingEvents = useSelector((state) => state.upcomingEvents.events);
  // const firstEvent = upcomingEvents[1];
  return (
    <>
      {" "}
      <Container>
        <Row className="justify-content-md-center">
          <FadeInAnimation>
            <Col xs={12}>
              <h1 className="title">This is Love on You Mommy Ministry</h1>
            </Col>
          </FadeInAnimation>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={12}>
            <FadeInAnimation>
              <img
                src="/img/Logo.svg"
                className={`${styles.titleImg} img-center`}
              />
            </FadeInAnimation>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} lg={6}>
            <VideoContainer>
              {" "}
              <video controls width="100%" height="auto">
                <source src="/vid/LOYM_Intro_Vid.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </VideoContainer>
          </Col>
          <Col xs={12} lg={6}>
            <h2 className="header">About us</h2>
            <TextContainer>
              <p className={styles.aboutText}>
                The Mission of Love on You Mommy Ministry is based on Isaiah
                62:3. It's our mission to affirm and assert Moms of all various
                ages, backgrounds, and stages in life of this unconditional
                truth...
                <br />
                <br />
                <Link
                  to="whoweare"
                  className={`${styles.aboutText} ${styles.link}`}
                >
                  Read more
                </Link>
              </p>
            </TextContainer>
          </Col>
        </Row>
      </Container>
      <br />
      <h2 className="header">Upcoming events</h2>
      <UpcomingEvents />
      <br />
      <h2 className="header">Testimonials</h2>
      <br />
      <TestimonalCarousel />
      <br />
      <br />
    </>
  );
};

export default HomeScreen;
